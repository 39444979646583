import React, { useContext } from "react";
import { WarrantContext } from "../context/warrantProvider.js";
import BubbleGraph from "../components/BubbleGraph.js";
import { useQuery } from "react-query";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

export default function TrendsPage() {
  const { fetchData, warrants } = useContext(WarrantContext);

  const { isLoading: w_loading } = useQuery({
    queryKey: "all_warrants",
    queryFn: () => fetchData(),
    config: {},
  });

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div>
      {!w_loading && warrants ? (
        <div>
          <BubbleGraph
            matches={matches}
            warrants={Object.values(warrants)
              .filter(
                (warrant) =>
                  warrant.warrant_pricing_information &&
                  warrant.warrant_pricing_information.changePercent &&
                  warrant.warrant_pricing_information.latestVolume &&
                  warrant.warrant_ticker &&
                  warrant.spac
              )
              .map((warrant) => {
                return {
                  changePercent:
                    warrant.warrant_pricing_information.changePercent,
                  volume: warrant.warrant_pricing_information.latestVolume,
                  ticker: warrant.warrant_ticker,
                };
              })}
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
