import React, { useContext } from "react"
import ContactForm from "../components/ContactForm.js"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { useTheme, makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Paper from "@material-ui/core/Paper"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import RedditIcon from "@material-ui/icons/Reddit"
import Link from "@material-ui/core/Link"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import { format } from "d3-format"
import { WarrantContext } from "../context/warrantProvider.js"
import { useQuery } from "react-query"

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    display: "flex",
    marginBottom: "4rem",
    marginTop: "2rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  info: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "1rem",
  },
  form: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  innerCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
})

export default function ContactPage() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))
  const classes = useStyles()

  const { fetchData, warrants } = useContext(WarrantContext)

  const { isLoading: w_loading } = useQuery({
    queryKey: "all_warrants",
    queryFn: () => fetchData(),
    config: {},
  })

  const sumOfArrayWithParameter = (array, parameter1, parameter2) => {
    let sum = null
    if (array && array.length > 0) {
      sum = 0
      for (let e of array) {
        if (e && e.hasOwnProperty(parameter1))
          sum += Number(e[parameter1][parameter2])
      }
    }
    return sum
  }

  return (
    <Box
      paddingBottom="2rem"
      paddingTop="1rem"
      marginBottom={matches ? "6rem" : "0rem"}
    >
      <Box display="flex" justifyContent="center">
        {matches ? (
          <Box className={classes.root}>
            <Box className={classes.info}>
              <Card padding="2rem">
                <CardContent>
                  <Box className={classes.innerCard}>
                    <Typography color="primary" variant="h4">
                      CONTACT FORM
                    </Typography>

                    <Box padding="2rem">
                      <Typography color="secondary" variant="body1">
                        Please fill out the form to discuss additional warrant
                        data and analytics, API access, advertising /
                        partnerships, and any bugs or suggestions regarding the
                        site or its content. Our goal is to make warrants more
                        widely accessible and useful to investors globally.
                      </Typography>
                    </Box>

                    <Box marginBottom="1rem">
                      <Typography color="secondary" variant="body1">
                        <b> SPAC Statistics </b>
                      </Typography>
                    </Box>

                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Paper elevation={2}>
                          <Box paddingTop="1rem" paddingBottom="1rem">
                            <Typography
                              color="secondary"
                              variant="body1"
                              align="center"
                            >
                              Avg Common Price - $
                              {!w_loading
                                ? Number(
                                    (
                                      sumOfArrayWithParameter(
                                        Object.values(warrants).filter(
                                          (ticker) => {
                                            return (
                                              ticker.warrant_pricing_information &&
                                              Boolean(ticker.spac)
                                            )
                                          }
                                        ),
                                        "common_pricing_information",
                                        "latestPrice"
                                      ) /
                                      Object.values(warrants).filter(
                                        (ticker) => {
                                          return (
                                            ticker.common_pricing_information &&
                                            Boolean(ticker.spac)
                                          )
                                        }
                                      ).length
                                    ).toFixed(2)
                                  )
                                : ""}
                            </Typography>

                            <Typography
                              color="secondary"
                              variant="body1"
                              align="center"
                            >
                              Avg Common Volume -{" "}
                              {!w_loading
                                ? format(".1s")(
                                    Number(
                                      (
                                        sumOfArrayWithParameter(
                                          Object.values(warrants).filter(
                                            (ticker) => {
                                              return (
                                                ticker.warrant_pricing_information &&
                                                Boolean(ticker.spac)
                                              )
                                            }
                                          ),
                                          "common_pricing_information",
                                          "latestVolume"
                                        ) /
                                        Object.values(warrants).filter(
                                          (ticker) => {
                                            return (
                                              ticker.common_pricing_information &&
                                              Boolean(ticker.spac)
                                            )
                                          }
                                        ).length
                                      ).toFixed(0)
                                    )
                                  )
                                : ""}
                            </Typography>
                          </Box>
                        </Paper>
                      </Grid>
                      <Grid xs={6} item>
                        <Paper elevation={2}>
                          <Box paddingTop="1rem" paddingBottom="1rem">
                            <Typography
                              color="secondary"
                              variant="body1"
                              align="center"
                            >
                              Avg Warrant Price - $
                              {Number(
                                (
                                  sumOfArrayWithParameter(
                                    Object.values(warrants).filter((ticker) => {
                                      return (
                                        ticker.warrant_pricing_information &&
                                        ticker.warrant_pricing_information
                                          .latestPrice &&
                                        Boolean(ticker.spac)
                                      )
                                    }),
                                    "warrant_pricing_information",
                                    "latestPrice"
                                  ) /
                                  Object.values(warrants).filter((ticker) => {
                                    return (
                                      ticker.warrant_pricing_information &&
                                      Boolean(ticker.spac)
                                    )
                                  }).length
                                ).toFixed(2)
                              )}
                            </Typography>
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>

                    <Box marginTop="1rem">
                      <Paper elevation={2}>
                        <Box padding="1rem">
                          <Typography color="secondary" variant="body1">
                            Total SPACs with Warrants -{" "}
                            {
                              Object.values(warrants).filter((ticker) => {
                                return (
                                  ticker.warrant_pricing_information &&
                                  Boolean(ticker.spac)
                                )
                              }).length
                            }
                          </Typography>
                        </Box>
                      </Paper>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box className={classes.form}>
              <Box display="flex" justifyContent="center">
                <ContactForm />
              </Box>
              <Box display="flex" justifyContent="center">
                <Link
                  href={`https://www.reddit.com/r/SPACs/comments/icya8v/a_beginners_faq_guide_to_spac_warrants/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<RedditIcon />}
                  >
                    Beginner FAQ Guide
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        ) : (
          <div>
            <Box width={1}>
              <Box paddingTop="1rem" display="flex" justifyContent="center">
                <Typography color="primary" variant="h4">
                  <b> CONTACT FORM </b>
                </Typography>
              </Box>

              <Box marginTop="1rem" padding="1rem" marginBottom="1rem">
                <Paper elevation={2}>
                  <Box padding="0.5rem">
                    <Typography color="secondary" variant="subtitle2">
                      Please fill out the form to discuss additional warrant
                      data and analytics, API access, advertising /
                      partnerships, and any bugs or suggestions regarding the
                      site or its content. Our goal is to make warrants more
                      widely accessible and useful to investors globally.
                    </Typography>
                  </Box>
                </Paper>
              </Box>

              <Box marginBottom="1rem">
                <Typography color="secondary" variant="body1" align="center">
                  <b> SPAC Statistics </b>
                </Typography>
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Paper elevation={2}>
                    <Box padding="0.5rem">
                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        Avg Common Price - $
                        {Number(
                          (
                            sumOfArrayWithParameter(
                              Object.values(warrants).filter((ticker) => {
                                return (
                                  ticker.warrant_pricing_information &&
                                  Boolean(ticker.spac)
                                )
                              }),
                              "common_pricing_information",
                              "latestPrice"
                            ) /
                            Object.values(warrants).filter((ticker) => {
                              return (
                                ticker.common_pricing_information &&
                                Boolean(ticker.spac)
                              )
                            }).length
                          ).toFixed(2)
                        )}
                      </Typography>

                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        Avg Common Volume -{" "}
                        {format(".1s")(
                          Number(
                            (
                              sumOfArrayWithParameter(
                                Object.values(warrants).filter((ticker) => {
                                  return (
                                    ticker.warrant_pricing_information &&
                                    Boolean(ticker.spac)
                                  )
                                }),
                                "common_pricing_information",
                                "latestVolume"
                              ) /
                              Object.values(warrants).filter((ticker) => {
                                return (
                                  ticker.common_pricing_information &&
                                  Boolean(ticker.spac)
                                )
                              }).length
                            ).toFixed(0)
                          )
                        )}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
                <Grid xs={6} item>
                  <Paper elevation={2}>
                    <Box padding="0.5rem">
                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        Avg Warrant Price - $
                        {Number(
                          (
                            sumOfArrayWithParameter(
                              Object.values(warrants).filter((ticker) => {
                                return (
                                  ticker.warrant_pricing_information &&
                                  ticker.warrant_pricing_information
                                    .latestPrice &&
                                  Boolean(ticker.spac)
                                )
                              }),
                              "warrant_pricing_information",
                              "latestPrice"
                            ) /
                            Object.values(warrants).filter((ticker) => {
                              return (
                                ticker.warrant_pricing_information &&
                                Boolean(ticker.spac)
                              )
                            }).length
                          ).toFixed(3)
                        )}
                      </Typography>

                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        Avg Warrant Volume -{" "}
                        {format(".1s")(
                          Number(
                            (
                              sumOfArrayWithParameter(
                                Object.values(warrants).filter((ticker) => {
                                  return (
                                    ticker.warrant_pricing_information &&
                                    ticker.warrant_pricing_information
                                      .latestPrice &&
                                    Boolean(ticker.spac)
                                  )
                                }),
                                "warrant_pricing_information",
                                "latestVolume"
                              ) /
                              Object.values(warrants).filter((ticker) => {
                                return (
                                  ticker.warrant_pricing_information &&
                                  Boolean(ticker.spac)
                                )
                              }).length
                            ).toFixed(2)
                          )
                        )}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>

              <Box marginTop="1rem" marginLeft="2rem" marginRight="2rem">
                <Paper elevation={2}>
                  <Box paddingTop="1rem" paddingBottom="1rem">
                    <Typography
                      color="secondary"
                      variant="body1"
                      align="center"
                    >
                      Total SPACs with Warrants -{" "}
                      {
                        Object.values(warrants).filter((ticker) => {
                          return (
                            ticker.warrant_pricing_information &&
                            Boolean(ticker.spac)
                          )
                        }).length
                      }
                    </Typography>
                  </Box>
                </Paper>
              </Box>
            </Box>
            <Box paddingBottom="1rem" paddingTop="2rem">
              <Box
                width={1}
                paddingBottom="1rem"
                display="flex"
                justifyContent="center"
              >
                <ContactForm />
              </Box>
            </Box>
          </div>
        )}
      </Box>

      <Box paddingBottom="2rem" paddingTop="1rem">
        <Box marginBottom="1rem">
          <Typography color="secondary" variant="body1" align="center">
            <b> Warrant Terms </b>
          </Typography>
        </Box>
        <Box>
          {matches ? (
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Paper elevation={2}>
                  <Box component="span">
                    <Box paddingTop=".75rem">
                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        <b>Warrants</b>
                      </Typography>
                    </Box>
                    <Box padding="1rem">
                      <Typography
                        color="primary"
                        variant="caption"
                        align="center"
                        gutterBottom
                      >
                        Options issued by the SPAC to buy shares on an
                        underlying company at a predetermined price – typically
                        at $11.50. SPAC warrants typically have a five-year term
                        after any merger until they expire. However, SPAC
                        warrants expire worthless if the SPAC can't close a
                        deal. The speculative nature of these securities tend to
                        lead to price swings.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={3}>
                <Paper elevation={2}>
                  <Box component="span">
                    <Box paddingTop=".75rem">
                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        <b>Units</b>
                      </Typography>
                    </Box>
                    <Box padding="1rem">
                      <Typography
                        color="primary"
                        variant="caption"
                        align="center"
                        gutterBottom
                      >
                        When the IPO occurs, a SPAC offers Units at $10. These
                        Units are comprised of 1 share of common stock and a
                        warrant (or a portion) to purchase common stock. Shortly
                        after the IPO, the common stock and warrants embedded in
                        units become separable. At that point, the warrants and
                        common shares trade separately.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={3}>
                <Paper elevation={2}>
                  <Box component="span">
                    <Box paddingTop=".75rem">
                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        <b>Intrinsic Value</b>
                      </Typography>
                    </Box>
                    <Box padding="1rem">
                      <Typography
                        color="primary"
                        variant="caption"
                        align="center"
                        gutterBottom
                      >
                        The difference between the exercise price and the actual
                        trading price of the common stock. This represents the
                        value of a warrant at the expiration date, since the
                        warrant can be exercised and shares sold immediately for
                        this value. Once the common has gone over the exercise
                        price the warrants are said to be "in" the money.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper elevation={2}>
                  <Box component="span">
                    <Box paddingTop=".75rem">
                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        <b>Volatility</b>
                      </Typography>
                    </Box>
                    <Box padding="1rem">
                      <Typography
                        color="primary"
                        variant="caption"
                        align="center"
                        gutterBottom
                      >
                        The higher the volatility rating the higher the price of
                        the warrant will be. This is as options (and warrants)
                        are priced using the Black-Scholes model. By measuring
                        stock price changes a specific period of time,
                        historical volatility is calculated by using the
                        standard deviation of an underlying stock price.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper elevation={2}>
                  <Box component="span">
                    <Box paddingTop=".75rem">
                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        <b>In/Out the Money</b>
                      </Typography>
                    </Box>
                    <Box padding="1rem">
                      <Typography
                        color="primary"
                        variant="caption"
                        align="center"
                        gutterBottom
                      >
                        "In" the money is when the price of the common is higher
                        than the exercise price. "Out" of the money is when the
                        price of the common is lower than the exercise price.
                        "At" of the money is when the price of the common is
                        very close to the exercise price.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper elevation={2}>
                  <Box component="span">
                    <Box paddingTop=".75rem">
                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        <b>Time Value</b>
                      </Typography>
                    </Box>
                    <Box padding="1rem">
                      <Typography
                        color="primary"
                        variant="caption"
                        align="center"
                        gutterBottom
                      >
                        The time value is the difference between the current
                        warrant and its intrinsic value The time value is to be
                        interpreted as the consideration paid for the advantage
                        that the warrant buyer has over the direct investor.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper elevation={2}>
                  <Box component="span">
                    <Box paddingTop=".75rem">
                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        <b>Expiration Date </b>
                      </Typography>
                    </Box>
                    <Box padding="1rem">
                      <Typography
                        color="primary"
                        variant="caption"
                        align="center"
                        gutterBottom
                      >
                        The last day the warrants can be exercised. If warrants
                        aren't going to be exercised then they must be sold the
                        day before the expiry date. The longer the time to
                        expiry the more valuable the warrants.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper elevation={2}>
                  <Box component="span">
                    <Box paddingTop=".75rem">
                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        <b>Leverage</b>
                      </Typography>
                    </Box>
                    <Box padding="1rem">
                      <Typography
                        color="primary"
                        variant="caption"
                        align="center"
                        gutterBottom
                      >
                        The leverage of a warrant is a measure of how much you
                        can increase your exposure to a share if you bought
                        warrants instead of making a direct investment. It is
                        current share price by the current price of the warrant.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper elevation={2}>
                  <Box component="span">
                    <Box paddingTop=".75rem">
                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        <b>Warrants</b>
                      </Typography>
                    </Box>
                    <Box padding="1rem">
                      <Typography
                        color="primary"
                        variant="caption"
                        align="center"
                        gutterBottom
                      >
                        Options issued by the SPAC to buy shares on an
                        underlying company at a predetermined price – typically
                        at $11.50. SPAC warrants typically have a five-year term
                        after any merger until they expire. However, SPAC
                        warrants expire worthless if the SPAC can't close a
                        deal. The speculative nature of these securities tend to
                        lead to price swings.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper elevation={2}>
                  <Box component="span">
                    <Box paddingTop=".75rem">
                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        <b>Units</b>
                      </Typography>
                    </Box>
                    <Box padding="1rem">
                      <Typography
                        color="primary"
                        variant="caption"
                        align="center"
                        gutterBottom
                      >
                        When the IPO occurs, a SPAC offers Units at $10. These
                        Units are comprised of 1 share of common stock and a
                        warrant (or a portion) to purchase common stock. Shortly
                        after the IPO, the common stock and warrants embedded in
                        units become separable. At that point, the warrants and
                        common shares trade separately.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper elevation={2}>
                  <Box component="span">
                    <Box paddingTop=".75rem">
                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        <b>Intrinsic Value</b>
                      </Typography>
                    </Box>
                    <Box padding="1rem">
                      <Typography
                        color="primary"
                        variant="caption"
                        align="center"
                        gutterBottom
                      >
                        The difference between the exercise price and the actual
                        trading price of the common stock. This represents the
                        value of a warrant at the expiration date, since the
                        warrant can be exercised and shares sold immediately for
                        this value. Once the common has gone over the exercise
                        price the warrants are said to be "in" the money.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={2}>
                  <Box component="span">
                    <Box paddingTop=".75rem">
                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        <b>Volatility</b>
                      </Typography>
                    </Box>
                    <Box padding="1rem">
                      <Typography
                        color="primary"
                        variant="caption"
                        align="center"
                        gutterBottom
                      >
                        The higher the volatility rating the higher the price of
                        the warrant will be. This is as options (and warrants)
                        are priced using the Black-Scholes model. By measuring
                        stock price changes a specific period of time,
                        historical volatility is calculated by using the
                        standard deviation of an underlying stock price.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={2}>
                  <Box component="span">
                    <Box paddingTop=".75rem">
                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        <b>In/Out the Money</b>
                      </Typography>
                    </Box>
                    <Box padding="1rem">
                      <Typography
                        color="primary"
                        variant="caption"
                        align="center"
                        gutterBottom
                      >
                        "In" the money is when the price of the common is higher
                        than the exercise price. "Out" of the money is when the
                        price of the common is lower than the exercise price.
                        "At" of the money is when the price of the common is
                        very close to the exercise price.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={2}>
                  <Box component="span">
                    <Box paddingTop=".75rem">
                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        <b>Time Value</b>
                      </Typography>
                    </Box>
                    <Box padding="1rem">
                      <Typography
                        color="primary"
                        variant="caption"
                        align="center"
                        gutterBottom
                      >
                        The time value is the difference between the current
                        warrant and its intrinsic value The time value is to be
                        interpreted as the consideration paid for the advantage
                        that the warrant buyer has over the direct investor.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={2}>
                  <Box component="span">
                    <Box paddingTop=".75rem">
                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        <b>Expiration Date </b>
                      </Typography>
                    </Box>
                    <Box padding="1rem">
                      <Typography
                        color="primary"
                        variant="caption"
                        align="center"
                        gutterBottom
                      >
                        The last day the warrants can be exercised. If warrants
                        aren't going to be exercised then they must be sold the
                        day before the expiry date. The longer the time to
                        expiry the more valuable the warrants.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={2}>
                  <Box component="span">
                    <Box paddingTop=".75rem">
                      <Typography
                        color="secondary"
                        variant="body1"
                        align="center"
                      >
                        <b>Leverage</b>
                      </Typography>
                    </Box>
                    <Box padding="1rem">
                      <Typography
                        color="primary"
                        variant="caption"
                        align="center"
                        gutterBottom
                      >
                        The leverage of a warrant is a measure of how much you
                        can increase your exposure to a share if you bought
                        warrants instead of making a direct investment. It is
                        current share price by the current price of the warrant.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </Box>
  )
}
