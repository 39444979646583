import React from "react";
import WatchListTable from "../components/WatchListTable.js";
import Box from "@material-ui/core/Box";

export default function WatchListPage() {
  //const { warrants } = useContext(WarrantContext);

  return (
    <div>
      <Box paddingTop="2rem" d="flex" justifyContent="center">
        <WatchListTable />
      </Box>
    </div>
  );
}
