import React from "react";
import { Treemap } from "react-vis";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { format as d3_format } from "d3-format";

const MODE = [
  "circlePack",
  "partition",
  "partition-pivot",
  "squarify",
  "resquarify",
  "slice",
  "dice",
  "slicedice",
  "binary",
];

const STYLES = {
  SVG: {
    stroke: "#FFF",
    strokeWidth: "0.1",
    strokeOpacity: 0.5,
  },
  DOM: {
    border: "thin solid #ddd",
  },
};

export default class BubbleGraph extends React.Component {
  state = {
    modeIndex: 0,
    useSVG: true,
  };

  render() {
    const { modeIndex, useSVG } = this.state;

    const volumeData = {
      color: "#932432",
      children: this.props.warrants
        .map((warrant) => {
          return {
            title:
              warrant.ticker +
              " - " +
              d3_format(".2s")(Math.abs(warrant.volume)),
            color: "#EDF4F5",
            size: warrant.volume,
          };
        })
        .sort((prev, next) => next.size - prev.size)
        .slice(0, 50),
    };

    const volumeData2 = {
      color: "#932432",
      children: this.props.warrants
        .map((warrant) => {
          return {
            title: warrant.ticker,
            color: "#EDF4F5",
            size: warrant.volume,
          };
        })
        .sort((prev, next) => next.size - prev.size)
        .slice(0, 25),
    };

    const changeData = {
      color: "#932432",
      children: this.props.warrants
        .map((warrant) => {
          return {
            title:
              warrant.ticker +
              " - " +
              d3_format(".0%")(Math.abs(warrant.changePercent)),
            color: warrant.changePercent > 0 ? "#90ee90" : "#f08080",
            size: Math.abs(warrant.changePercent),
          };
        })
        .sort((prev, next) => next.size - prev.size)
        .slice(0, 50),
    };

    const changeData2 = {
      color: "#932432",
      children: this.props.warrants
        .map((warrant) => {
          return {
            title: warrant.ticker,
            color: warrant.changePercent > 0 ? "#90ee90" : "#f08080",
            size: Math.abs(warrant.changePercent),
          };
        })
        .sort((prev, next) => next.size - prev.size)
        .slice(0, 20),
    };

    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className="outer"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          padding="1rem"
        >
          <Typography color="primary" variant="h5" align="center">
            <b>Daily SPAC Warrant Market Volumes</b>
          </Typography>
          <Box border={1} marginTop="1rem">
            {this.props.matches ? (
              <Treemap
                {...{
                  animation: true,
                  className: "nested-tree-example",
                  colorType: "literal",
                  colorRange: ["#88572C"],
                  data: volumeData,
                  mode: MODE[modeIndex],
                  renderMode: useSVG ? "SVG" : "DOM",
                  height: 800,
                  width: 1400,
                  margin: 20,
                  padding: 40,
                  hideRootNode: true,

                  style: STYLES[useSVG ? "SVG" : "DOM"],
                }}
              />
            ) : (
              <Treemap
                {...{
                  animation: true,
                  className: "nested-tree-example",
                  colorType: "literal",
                  colorRange: ["#88572C"],
                  data: volumeData2,
                  mode: MODE[modeIndex],
                  renderMode: useSVG ? "SVG" : "DOM",
                  height: 1000,
                  width: 400,
                  margin: 0,
                  padding: 0,
                  hideRootNode: true,
                  style: STYLES[useSVG ? "SVG" : "DOM"],
                }}
              />
            )}
          </Box>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          padding="1rem"
        >
          <Typography color="primary" variant="h5">
            <b>Daily SPAC Warrant Market Change %</b>
          </Typography>
          <Box border={1} marginTop="1rem">
            {this.props.matches ? (
              <Treemap
                {...{
                  animation: true,
                  className: "nested-tree-example",
                  colorType: "literal",
                  colorRange: ["#88572C"],
                  data: changeData,
                  mode: MODE[modeIndex],
                  renderMode: useSVG ? "SVG" : "DOM",
                  height: 800,
                  width: 1400,
                  margin: 20,
                  padding: 40,
                  hideRootNode: true,

                  style: STYLES[useSVG ? "SVG" : "DOM"],
                }}
              />
            ) : (
              <Treemap
                {...{
                  animation: true,
                  className: "nested-tree-example",
                  colorType: "literal",
                  colorRange: ["#88572C"],
                  data: changeData2,
                  mode: MODE[modeIndex],
                  renderMode: useSVG ? "SVG" : "DOM",
                  height: 1000,
                  width: 400,
                  margin: 0,
                  padding: 0,
                  hideRootNode: true,
                  style: STYLES[useSVG ? "SVG" : "DOM"],
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  }
}
