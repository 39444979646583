import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { SliderContext } from "../context/sliderProvider.js";
import JarAvatar from "./JarAvatar.js";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
  root: {
    width: 300,
  },
  sliderText: {
    fontWeight: 700,
    paddingRight: "1rem",
  },
  removeUnderline: {
    "text-decoration": "none !important",
  },
});

function valuetext(value) {
  return `$ ${value}`;
}

export default function RangeSlider() {
  const classes = useStyles();

  const { value, setValue } = useContext(SliderContext);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    window.localStorage.setItem("low", newValue[0]);
    window.localStorage.setItem("high", newValue[1]);
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center" alignContent="baseline">
        <Typography
          id="range-slider"
          color="primary"
          variant="h5"
          className={classes.sliderText}
        >
          Price Range
        </Typography>
        <JarAvatar slider_value={value} />
      </Box>
      <Slider
        value={value}
        min={0.0}
        max={20.0}
        valueLabelDisplay="auto"
        onChange={handleChange}
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        step={1}
      />
    </div>
  );
}
