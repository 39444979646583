import React from "react";
import SentimentChip from "./SentimentChip";
import formatRelative from "date-fns/formatRelative";

/* Material-UI Components */
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  avatarContainer: {
    display: "flex",
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  userAvatar: {
    width: 60,
    height: 60,
  },
  tweetCardHeader: {
    alignItems: "center",
    flexDirection: "row",
    display: "flex",
    marginBottom: theme.spacing(3 / 2),
  },
  topRightSection: {
    alignSelf: "flex-start",
    flexDirection: "column",
    display: "flex",
  },
  divider: {
    marginTop: 6,
    marginBottom: 10,
  },
  flex: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  tweetBody: {
    overflow: "hidden",
  },
}));

function TweetCard({ tweet }) {
  const classes = styles();
  const { user, text } = tweet;

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const timestamp = Date.parse(tweet.created_at);
  //const timestamp = moment(tweet.created_at).calendar();
  const followers = user.followers_count;

  return (
    <Paper className={classes.paper}>
      <div className={classes.tweetCardHeader}>
        <div className={classes.avatarContainer}>
          <Avatar
            className={classes.userAvatar}
            alt="user-avatar"
            src={user.profile_image_url_https}
          />
        </div>
        <div className={classes.flex}>
          <Typography variant="h5">{user.name}</Typography>
          <Typography>
            {"\u0040"}
            {user.screen_name}
          </Typography>
        </div>
        <div className={classes.topRightSection}>
          <Typography variant="subtitle2" color="secondary">
            {capitalize(formatRelative(timestamp, Date.now()))}
          </Typography>
          {followers && <SentimentChip followers_count={followers} />}
        </div>
      </div>
      <Divider className={classes.divider} />
      <Typography className={classes.tweetBody}>{text}</Typography>
    </Paper>
  );
}

export default TweetCard;
