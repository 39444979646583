import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import LoginButton from "../components/LoginButton";
import Avatar from "@material-ui/core/Avatar";
import logo from "../img/logo.png";
import Box from "@material-ui/core/Box";
import table from "../img/table.PNG";
import trends from "../img/trends.PNG";
import news from "../img/news.PNG";
import filings from "../img/filings.PNG";
import watchlist from "../img/watchlist.PNG";
import graphs from "../img/graphs.PNG";

function Copyright() {
  return (
    <Box>
      <Typography variant="body2" color="textSecondary" align="center">
        The information provided on this website does not, and is not intended
        to, constitute legal or investing advice; instead, all information,
        content, and materials available on this site are for informational
        purposes only. Information on this site is presented as-is, and without
        warranty of any kind. Copyright © {new Date().getFullYear()} -
        Kensington Investments LLC
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroContentMobile: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const cards = [
  {
    title: "Warrant Market Trends",
    content:
      "See how average warrant prices for SPACs have been trending - allowing you to measure whether the market is in a bubble. This may help you gage your exposure to beta in the warrant environment.",
    image: trends,
  },
  {
    title: "Labeled Warrant Graphs",
    content:
      "High-quality, accurate warrant pricing graphs with a large history. Includes filing annotations and volumes, allowing you to see what caused spikes or dips.",
    image: graphs,
  },
  {
    title: "SPAC News and Press Releases",
    content:
      "Track bundled news on SPAC related content, including the major press releases. Works based off a feed that updates LIVE.",
    image: news,
  },
  {
    title: "Relevant SPAC Filings",
    content:
      "Only see the latest, highly-relevant SPAC filings with our SEC monitoring feature. Updates every 15 minutes along with prices, volumes, and other data.",
    image: filings,
  },
  {
    title: "Watchlist of Top Warrants",
    content:
      "Monitor warrant movements to keep track of potential buy and sell opportunities on the companies you're familiar with.",
    image: table,
  },
  {
    title: "Table of Warrants",
    content:
      "Filter, sift, and sort through aggregated warrant data from our complex systems. We try to do the heavy lifting for you automatically.",
    image: watchlist,
  },
];

export default function LoginPage({ location }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const message = location?.state?.message;

  return (
    <React.Fragment>
      <CssBaseline />

      <main>
        {/* Hero unit */}
        <div
          className={matches ? classes.heroContent : classes.heroContentMobile}
        >
          <Container>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginBottom="1rem"
            >
              <Typography variant="h3" color="secondary" align="center" noWrap>
                Warrants Tech
              </Typography>

              <Box p="1rem">
                <Avatar alt="Logo" src={logo} className={classes.large} />
              </Box>
            </Box>
            {matches ? (
              <Typography variant="h6" align="center" color="primary" paragraph>
                Warrants Tech is now a part of The Warrant Observer - A
                membership to a highly-active discord community of warrant
                enthusiasts, monthly newsletter, and other exclusive content.
                Once you sign up for The Warrant Observer, simply sign up with
                the <b>same email </b> to access the warrant tracker. If you run
                into issues, ensure that you <b> verified your email </b> (Auth0
                sends one after signing up) and you have paid for the service.
                Send any inquries to stevenadams@stockwarrantshq.com.
              </Typography>
            ) : (
              <Typography
                variant="subtitle2"
                align="center"
                color="primary"
                paragraph
              >
                Warrants Tech is now a part of The Warrant Observer - A
                membership to a highly-active discord community of warrant
                enthusiasts, monthly newsletter, and other exclusive content.
                Once you sign up for The Warrant Observer, simply login with the{" "}
                <b>same email </b> to access the warrant tracker. If you run
                into issues, ensure that you verified your email (Auth0 sends
                after signing up) and you have paid for the service. Send any
                inquries to stevenadams@stockwarrantshq.com.
              </Typography>
            )}

            {!!message && <Alert severity="error">{message}</Alert>}
            <div className={classes.heroButtons}>
              <Grid container spacing={3} justify="center">
                <Grid item>
                  <LoginButton />
                </Grid>
                <Grid item>
                  <Link
                    href="https://gumroad.com/l/okQsi"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button variant="contained" color="primary">
                      Sign up to Warrant Observer
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <Container className={classes.cardGrid}>
          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card} xs={12} sm={8} md={6} key={card.title}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.image}
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.title}
                    </Typography>
                    <Typography>{card.content}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography variant="subtitle2" align="center" gutterBottom>
          Legal Disclaimer
        </Typography>

        <Copyright />
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}
