import React from "react";
import { useQuery } from "react-query";
import Box from "@material-ui/core/Box";
import NewsCard from "../components/NewsCard";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import fetchAll from "./fetchAll.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function NewsPage() {
  const classes = useStyles();

  const { isLoading, data: combined } = useQuery({
    queryKey: "combined",
    queryFn: () => fetchAll(),
  });

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div className={classes.root}>
      <Box padding="1rem">
        <Grid container spacing={3} alignItems="center" justify="center">
          {!isLoading
            ? combined.map((item, i) => {
                if (matches) {
                  return (
                    <Grid item xs={4} key={item.url + i}>
                      <NewsCard {...item} image_url={item.enclosure.url} />
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={12} key={item.url + i}>
                      <NewsCard {...item} image_url={item.enclosure.url} />
                    </Grid>
                  );
                }
              })
            : ""}
        </Grid>
      </Box>
    </div>
  );
}
