import React, { useState } from "react";
import Button from "@material-ui/core/Button";

export default function WatchListButton({ ticker }) {
  const [common, changeCommon] = useState(window.localStorage.getItem(ticker));

  const addToLocal = () => {
    window.localStorage.setItem(ticker, ticker);
    changeCommon(ticker);
  };

  const removeFromLocal = () => {
    window.localStorage.removeItem(ticker);
    changeCommon(null);
  };

  return (
    <div>
      {common ? (
        <Button variant="contained" onClick={removeFromLocal}>
          Remove From Watchlist
        </Button>
      ) : (
        <Button variant="contained" onClick={addToLocal}>
          Add to Watchlist
        </Button>
      )}
    </div>
  );
}
