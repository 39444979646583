import React from "react";
import { useLocation } from "react-router-dom";

import analytics from "./analytics";

export default function useGoogleAnalytics() {
  const location = useLocation();

  React.useEffect(() => {
    analytics.init();
  }, []);

  React.useEffect(() => {
    analytics.sendPageview(location.pathname);
  }, [location]);
}
