import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export default function Footer() {
  return (
    <Box padding="2rem">
      <Typography color="primary" variant="subtitle2">
        <b> Legal Disclaimer </b>- The information provided on this website does
        not, and is not intended to, constitute legal or investing advice;
        instead, all information, content, and materials available on this site
        are for informational purposes only. Information on this site is
        presented as-is, and without warranty of any kind. Copyright ©{" "}
        {new Date().getFullYear()} - Kensington Investments LLC
      </Typography>
    </Box>
  );
}
