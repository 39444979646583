import axios from "axios";

async function fetchAll() {
  const {
    data: { feed: news_data },
  } = await axios.get(`https://rss.app/api/widget/list/_g1wjCbQUmTiblmR8`);

  const {
    data: { feed: pr_data },
  } = await axios.get(`https://rss.app/api/widget/list/_dSaVDhCHqBJXBSs5`);

  const combined = news_data.items.concat(pr_data.items);

  for (let i = 0; i < combined.length; i++) {
    combined[i].title.substr(0, 50);
  }

  let uniqueChars = [];

  let seen = new Set();
  combined.forEach((c) => {
    if (!seen.has(c.title)) {
      uniqueChars.push(c);
      seen.add(c.title);
    }
  });

  return uniqueChars;
}

export default fetchAll;
