import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import WarrantProvider from "./context/warrantProvider.js";
import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "react-query";
import SliderProvider from "./context/sliderProvider.js";
const queryClient = new QueryClient();

const domain = "warrantstech.us.auth0.com";
const clientId = "zYvlBemaMJx4Yws92K3FLXzvkKe0tRz7";

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
    >
      <QueryClientProvider client={queryClient}>
        <WarrantProvider>
          <SliderProvider>
            <App />
          </SliderProvider>
        </WarrantProvider>
      </QueryClientProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
