import React, { useContext } from "react"
import { useTheme, makeStyles } from "@material-ui/core/styles"
import MaterialTable from "material-table"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { WarrantContext } from "../context/warrantProvider.js"
import { useHistory } from "react-router-dom"
import parse from "date-fns/parse"
import { format as d3_format } from "d3-format"
import format from "date-fns/format"
import Box from "@material-ui/core/Box"
import Avatar from "@material-ui/core/Avatar"
import green_up from "../img/green.png"
import red_down from "../img/red.png"
import { useQuery } from "react-query"
import Link from "@material-ui/core/Link"

const useStyles = makeStyles({
  emoji: {
    width: "1.5rem",
    height: "auto",
    marginLeft: ".1rem",
  },
  green: {
    color: "#1EB300",
  },
  red: {
    color: "#CC0000",
  },
})

function fractionToNumber(fraction = "") {
  const fractionParts = fraction.split("/")
  const numerator = fractionParts[0] || "0"
  const denominator = fractionParts[1] || "1"
  const radix = 10
  const number = parseInt(numerator, radix) / parseInt(denominator, radix)
  const result = number || 0

  return result
}

export default function WatchListTable() {
  const { fetchData, warrants } = useContext(WarrantContext)

  const { isLoading: w_loading } = useQuery({
    queryKey: "all_warrants",
    queryFn: () => fetchData(),
    config: {},
  })

  const classes = useStyles()

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  const padding = matches ? "1rem" : "0rem"

  let history = useHistory()

  return (
    <div style={{ maxWidth: "100%", padding }}>
      {matches ? (
        <MaterialTable
          data={
            !w_loading
              ? Object.values(warrants).filter(
                  (ticker) =>
                    ticker.warrant_pricing_information &&
                    ticker.warrant_pricing_information.latestPrice &&
                    !!window.localStorage.getItem(ticker.common_ticker)
                )
              : []
          }
          columns={[
            {
              title: "Company",
              field: "companyName",
              sorting: false,
            },

            { title: "Unit Ticker", field: "unit_ticker" },
            {
              title: "Unit",
              field: "unit_pricing_information.latestPrice",
              type: "currency",
              cellStyle: {
                width: "6rem",
                maxWidth: "6rem",
                textAlign: "center",
              },
              headerStyle: {
                width: "6rem",
                maxWidth: "6rem",
              },
              emptyValue: null,
            },
            {
              title: "Common Ticker",
              field: "common_ticker",
              cellStyle: {
                width: "6rem",
                maxWidth: "6rem",
                textAlign: "center",
              },
              headerStyle: {
                width: "6rem",
                maxWidth: "6rem",
              },
            },

            {
              title: "Common",
              field: "common_pricing_information.latestPrice",
              type: "currency",
              cellStyle: {
                width: "6rem",
                maxWidth: "6rem",
                textAlign: "center",
              },
              headerStyle: {
                width: "6rem",
                maxWidth: "6rem",
              },
            },

            { title: "Warrant Ticker", field: "warrant_ticker" },
            {
              title: "Warrant",
              field: "warrant_pricing_information.latestPrice",
              type: "currency",
              cellStyle: {
                width: "6rem",
                maxWidth: "6rem",
                textAlign: "center",
              },
              headerStyle: {
                width: "6rem",
                maxWidth: "6rem",
              },

              customSort: (a, b) =>
                a.warrant_pricing_information.latestPrice -
                b.warrant_pricing_information.latestPrice,
            },
            {
              title: "Daily % Change",
              field: "warrant_pricing_information.changePercent",
              cellStyle: {
                width: "4rem",
                maxWidth: "4rem",
              },
              headerStyle: {
                maxWidth: "6rem",
                width: "6rem",
              },
              emptyValue: null,

              render: (rowData) => {
                if (rowData.warrant_pricing_information.changePercent === 0) {
                  return null
                }
                return (
                  <Box display="flex" alignItems="center" justifyContent="left">
                    <div>
                      <p
                        className={
                          rowData.warrant_pricing_information.changePercent > 0
                            ? classes.green
                            : classes.red
                        }
                      >
                        {d3_format(".0%")(
                          Math.abs(
                            rowData.warrant_pricing_information.changePercent
                          )
                        )}
                      </p>
                    </div>
                    {rowData.warrant_pricing_information.changePercent > 0 ? (
                      // <span
                      //   role="img"
                      //   aria-label="arrow-down"
                      //   className={classes.emoji}
                      // >
                      //   📈
                      // </span>
                      <Avatar
                        className={classes.emoji}
                        alt="green_up"
                        src={green_up}
                      />
                    ) : (
                      // <span
                      //   role="img"
                      //   aria-label="arrow-down"
                      //   className={classes.emoji}
                      // >
                      //   📉
                      // </span>
                      <Avatar
                        className={classes.emoji}
                        alt="red_down"
                        src={red_down}
                      />
                    )}
                  </Box>
                )
              },
            },
            {
              title: "Volume",
              field: "warrant_pricing_information.latestVolume",
              cellStyle: {
                textAlign: "center",
              },
              emptyValue: null,
              render: (rowData) => {
                if (rowData.warrant_pricing_information.latestVolume === 0) {
                  return null
                }
                return d3_format(".1s")(
                  rowData.warrant_pricing_information.latestVolume
                )
              },
            },
            //          {
            //            title: "Implied Unit",
            //            field: "implied_unit",

            //            render: (rowData) => {
            //              if (!rowData.implied_unit) {
            //                return null;
            //              }
            //              return Number(rowData.implied_unit.toFixed(2));
            //            },

            //            emptyValue: null,

            //            headerStyle: {
            //              maxWidth: "6rem",
            //              width: "6rem",
            //            },
            //            cellStyle: {
            //              maxWidth: "4rem",

            //              width: "4rem",
            //            },
            //          },

            {
              title: "Low",
              field: "warrant_pricing_information.week52Low",
              type: "currency",

              customSort: (a, b) =>
                a.warrant_pricing_information.week52Low -
                b.warrant_pricing_information.week52Low,
            },
            {
              title: "High",
              field: "warrant_pricing_information.week52High",
              type: "currency",

              customSort: (a, b) =>
                a.warrant_pricing_information.week52High -
                b.warrant_pricing_information.week52High,
            },

            //          {
            //            title: "Amount Raised",
            //            field: "regex_amount_raised",
            //            render: (rowData) => {
            //              if (
            //                rowData.regex_amount_raised &&
            //                rowData.regex_amount_raised > 0
            //              ) {
            //                return d3_format(".3s")(rowData.regex_amount_raised).replace(
            //                  /G/,
            //                  "B"
            //                );
            //              }
            //              return null;
            //            },
            //          },
            //          {
            //            title: "Status",
            //            field: "regex_status",
            //            disableClick: true,
            //            render: (rowData) => {
            //              if (rowData.regex_status === "Seeking Target") {
            //                return rowData.regex_status;
            //              } else {
            //                return (
            //                  <Link
            //                    href={rowData.target_announced_link}
            //                    target="_blank"
            //                    rel="noreferrer"
            //                  >
            //                    {rowData.regex_status}
            //                  </Link>
            //                );
            //              }
            //            },

            //            cellStyle: {
            //              width: "10rem",
            //              minWidth: "10rem",
            //            },
            //            headerStyle: {
            //              width: "10rem",
            //              minWidth: "10rem",
            //            },
            //          },

            // {
            //   title: "Amount Raised",
            //   field: "regex_amount_raised",
            //   render: (rowData) => {
            //     if (rowData.regex_amount_raised) {
            //       return d3_format(".3s")(rowData.regex_amount_raised).replace(
            //         /G/,
            //         "B"
            //       );
            //     }
            //     return null;
            //   },
            // },
            // },
            // {
            //   title: "Progress to Due",
            //   field: "due",
            //   render: (rowData) => {
            //     if (rowData.due && rowData.formation) {
            //       const date_due = parse(rowData.due, "yyyy-MM-dd", new Date());
            //       const date_formation = parse(
            //         rowData.formation,
            //         "yyyy-MM-dd",
            //         new Date()
            //       );
            //       const today = new Date();

            //       const passed = differenceInDays(today, date_formation);
            //       const total = differenceInDays(date_due, date_formation);

            //       const ret = ((passed / total) * 100).toFixed(0);
            //       if (!isNaN(passed) && !isNaN(total)) {
            //         return `${ret}%`;
            //       }
            //     }
            //     return null;
            //   },
            //   cellStyle: {
            //     width: "6rem",
            //     maxWidth: "6rem",
            //     textAlign: "center",
            //   },
            //   headerStyle: {
            //     width: "7rem",
            //     maxWidth: "7rem",
            //   },

            //   customSort: (a, b) => {
            //     let new_a = a.due;
            //     let new_b = b.due;
            //     if (!new_a) {
            //       return -1;
            //     }
            //     if (!new_b) {
            //       return 1;
            //     }

            //     return (
            //       parse(new_a, "yyyy-MM-dd", new Date()) -
            //       parse(new_b, "yyyy-MM-dd", new Date())
            //     );
            //   },
            // },

            // {
            //   title: "Shr/Wt",
            //   field: "shr_per_unit",
            //   sorting: true,
            // },
            // {
            //   title: "Tags",
            //   field: "tags",
            //   sorting: true,
            // },
            // {
            //   title: "Wt/Ut",
            //   field: "regex_unit_info",
            //   sorting: false,
            //   headerStyle: {
            //     minWidth: "15rem",
            //   },
            //   cellStyle: {
            //     minWidth: "15rem",
            //   },
            // },

            //          {
            //            title: "Progress To Date",
            //            field: "progress_to_due",
            //            render: (rowData) => {
            //              if (rowData.progress_to_due) {
            //                return rowData.progress_to_due + "%";
            //              }
            //              return null;
            //            },
            //          },
            {
              title: "Expiry",
              field: "expiry",
              sorting: true,
              render: (rowData) => {
                let date
                if (rowData.expiry) {
                  date = parse(rowData.expiry, "dd/MM/yyyy", new Date())
                  const ret = format(date, "MMM d yyyy")

                  if (ret === "Jan 1 9999" || ret === "Jan 1 1999") {
                    return null
                  }

                  return ret
                }
                return null
              },
            },

            //          {
            //            title: "Focus",
            //            field: "tags",
            //            sorting: false,
            //            headerStyle: {
            //              minWidth: "10rem",
            //            },
            //            cellStyle: {
            //              minWidth: "10rem",
            //            },
            //          },
            // {
            //   title: "Volume Ratio",
            //   field: "volume_ratio",

            //   render: (rowData) => {
            //     if (!rowData.volume_ratio) {
            //       return null;
            //     }
            //     return Number(rowData.volume_ratio.toFixed(2));
            //   },

            //   emptyValue: null,

            //   headerStyle: {
            //     maxWidth: "6rem",
            //     width: "6rem",
            //   },
            //   cellStyle: {
            //     maxWidth: "4rem",

            //     width: "4rem",
            //   },
            // },
          ]}
          title="Warrant Information"
          options={{
            pageSize: 25,
            showTitle: false,
            pageSizeOptions: [10, 25, 50],
            padding: "dense",

            rowStyle: (x) => {
              let backgroundColor = "#FFFFFF"
              if (x.spac) {
                backgroundColor = "rgba(152, 118, 54, 0.075)"
              }

              return { backgroundColor, fontFamily: "Open Sans" }
            },
          }}
          onRowClick={(event, rowData) =>
            history.push(`/details/${rowData.common_ticker}`)
          }
        />
      ) : (
        <MaterialTable
          data={
            !w_loading
              ? Object.values(warrants).filter(
                  (ticker) =>
                    ticker.warrant_pricing_information &&
                    ticker.warrant_pricing_information.latestPrice &&
                    !!window.localStorage.getItem(ticker.common_ticker)
                )
              : []
          }
          columns={[
            {
              title: "Ticker",
              field: "warrant_ticker",
              headerStyle: {
                maxWidth: "6rem",
                width: "6rem",
              },
              cellStyle: {
                maxWidth: "6rem",
                width: "6rem",
              },
            },
            {
              title: "Company",
              field: "companyName",
            },

            {
              title: "Price",
              field: "warrant_pricing_information.latestPrice",
              type: "currency",
              cellStyle: {
                textAlign: "center",
              },
              customSort: (a, b) =>
                a.warrant_pricing_information.latestPrice -
                b.warrant_pricing_information.latestPrice,
            },

            {
              title: "Daily Volume",
              field: "warrant_pricing_information.latestVolume",

              emptyValue: null,

              render: (rowData) => {
                if (rowData.warrant_pricing_information.latestVolume === 0) {
                  return null
                }
                return d3_format(".1s")(
                  rowData.warrant_pricing_information.latestVolume
                )
              },
              headerStyle: {
                maxWidth: "6rem",
                width: "6rem",
              },
              cellStyle: {
                maxWidth: "4rem",

                width: "4rem",
              },
            },
            {
              title: "Daily % Change",
              field: "warrant_pricing_information.changePercent",

              emptyValue: null,

              render: (rowData) => {
                if (rowData.warrant_pricing_information.changePercent === 0) {
                  return null
                }
                return (
                  <Box display="flex" alignItems="center" justifyContent="left">
                    <div>
                      {d3_format(".0%")(
                        Math.abs(
                          rowData.warrant_pricing_information.changePercent
                        )
                      )}
                    </div>
                    {rowData.warrant_pricing_information.changePercent > 0 ? (
                      <span
                        role="img"
                        aria-label="arrow-down"
                        className={classes.emoji}
                      >
                        📈
                      </span>
                    ) : (
                      <span
                        role="img"
                        aria-label="arrow-down"
                        className={classes.emoji}
                      >
                        📉
                      </span>
                    )}
                  </Box>
                )
              },
            },
          ]}
          title="Warrant Information"
          options={{
            pageSize: 25,
            showTitle: false,
            pageSizeOptions: [10, 25, 50],
            padding: "dense",
            exportButton: true,
            rowStyle: (x) => {
              let backgroundColor = "#FFFFFF"
              if (x.spac) {
                backgroundColor = "rgba(152, 118, 54, 0.075)"
              }
              return {
                backgroundColor,
                fontFamily: "Open Sans",
                fontSize: "1rem",
              }
            },
          }}
          onRowClick={(event, rowData) => {
            history.push(`/details/${rowData.common_ticker}`)
          }}
          onChangeRowsPerPage={(value) => {
            window.localStorage.setItem("pageSize", value)
          }}
        />
      )}
    </div>
  )
}
