import React, { useState } from "react";
import RangeSlider from "../components/RangeSlider.js";
import Table from "../components/Table.js";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//import Card from "@material-ui/core/Card";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import TwitterIcon from "@material-ui/icons/Twitter";
import IconButton from "@material-ui/core/IconButton";
import { useQuery } from "react-query";

import axios from "axios";
import Avatar from "@material-ui/core/Avatar";
import discord from "../img/adams.jfif";

const useStyles = makeStyles({
  root: {},
  media_mobile: {
    maxHeight: "5rem",
    width: "auto",
    margin: "auto",
    border: "solid",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  media_pc: {
    maxHeight: "8rem",
    width: "auto",
    margin: "auto",
    border: "solid",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  title: {
    margin: "0rem 1rem 1rem 1rem",
    padding: "1rem 1rem 1rem 1rem",
    fontWeight: 200,
  },

  twitter: {
    color: "#00acee",
  },
  twitter2: {
    color: "#ff8c00",
  },
  ad: {
    paddingTop: "1rem",
    paddingLeft: "3rem",
    paddingRight: "3rem",
  },
  removeUnderline: {
    "text-decoration": "none !important",
  },
});

async function getFailed() {
  const { data } = await axios.get(`https://warrants.tech/api/failed`);

  return data;
}

export default function HomePage() {
  const classes = useStyles();

  const { isLoading: f_loading, data: failed } = useQuery({
    queryKey: "failed",
    queryFn: () => getFailed(),
    config: {},
  });

  const [value, changeValue] = useState(window.localStorage.getItem("value"));

  const handleChange = (e) => {
    e.preventDefault();
    window.localStorage.setItem("value", e.target.value);
    changeValue(e.target.value);
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        paddingLeft="1rem"
        flexWrap="wrap"
        paddingTop="1rem"
        paddingBottom="1rem"
      >
        <RangeSlider />

        <Box marginBottom="0.25rem">
          <FormControl component="fieldset">
            <RadioGroup
              name="table-selector"
              value={value ? value : "all"}
              onChange={handleChange}
            >
              <FormControlLabel
                value="all"
                control={<Radio />}
                label="All Warrants"
              />
              <FormControlLabel
                value="spacs"
                control={<Radio />}
                label="Pre-Close SPACs"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box
          margin=".5rem"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          boxShadow={1}
          paddingTop=".5rem"
          paddingBottom="1rem"
          paddingLeft="1.5rem"
          paddingRight="1.5rem"
        >
          <Box>
            <Typography variant="subtitle2" align="center">
              POWERED BY THE WARRANT OBSERVER
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box marginRight="2rem">
              <Link
                href={`mailto:stevenadams@stockwarrantshq.com?subject=Warrants Tech Inquiry`}
                target="_blank"
                rel="noreferrer"
              >
                <Avatar
                  alt="steven adams"
                  className={classes.img}
                  src={discord}
                />
              </Link>
            </Box>
            <Box>
              <Link
                href={`https://twitter.com/WarrantsTech`}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton
                  aria-label="twitter"
                  component="span"
                  className={classes.twitter}
                >
                  <TwitterIcon />
                </IconButton>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>

      <Table setting={value ? value : "all"} />

      {/* <Box
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        flexWrap="wrap"
        marginTop="1rem"
        paddingTop="1rem"
        paddingBottom="1rem"
      >
        <Card className={classes.title}>
          <Box marginBottom="0.5rem">
            <Typography variant="subtitle2" align="center">
              <b>Unable to find common tickers for warrants:</b>
            </Typography>
          </Box>
          <Typography color="secondary" variant="body2">
            {!f_loading && failed && failed.length ? failed.join(", ") : ""}
          </Typography>
        </Card>
      </Box> */}
    </div>
  );
}
