import React from "react";

import Chip from "@material-ui/core/Chip";

const SentimentChip = ({ followers_count }) => {
  return (
    <Chip
      label={`${followers_count} Followers`}
      size="small"
      variant="outlined"
    />
  );
};

export default SentimentChip;
