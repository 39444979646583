import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import { parseISO, formatDistanceToNow, isValid } from "date-fns";

const useStyles = makeStyles({
  root: {
    maxWidth: 465,
  },
  media: {
    maxHeight: 250,
    margin: "auto",
  },
  progress: {
    margin: "auto",
  },
});

export default function NewsCard({
  author,
  date,
  description,
  image_url,
  title,
  url,
}) {
  const classes = useStyles();

  const text_truncate = (str, length, ending) => {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  return (
    <Box display="flex" justifyContent="center" m={1} p={1}>
      <Card className={classes.root} variant="outlined">
        <Link href={url} target="_blank" rel="noreferrer">
          <CardActionArea>
            {image_url ? (
              <Box display="flex" justifyContent="center">
                <img src={image_url} className={classes.media} alt={title} />
              </Box>
            ) : (
              ""
            )}

            <CardContent>
              <Typography gutterBottom variant="subtitle2" component="h2">
                <div>
                  <b>{title.replace(/(<([^>]+)>)/gi, "")}</b>
                </div>
              </Typography>

              <Typography
                gutterBottom
                variant="overline"
                component="h2"
                color="primary"
              >
                {isValid(parseISO(date))
                  ? formatDistanceToNow(parseISO(date), {
                      addSuffix: true,
                    })
                  : date
                  ? formatDistanceToNow(date, { addSuffix: true })
                  : ""}
              </Typography>

              {image_url ? (
                <Typography variant="body2" color="secondary" component="p">
                  {description
                    ? text_truncate(
                        description.replace(/(<([^>]+)>)/gi, ""),
                        200
                      )
                    : ""}
                </Typography>
              ) : (
                <Typography variant="body2" color="secondary" component="p">
                  {description
                    ? text_truncate(
                        description.replace(/(<([^>]+)>)/gi, ""),
                        500
                      )
                    : ""}
                </Typography>
              )}
            </CardContent>
          </CardActionArea>
        </Link>
      </Card>
    </Box>
  );
}
