import React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import axios from "axios";
import NewsTimeline from "../components/NewsTimeline";
import { useQuery } from "react-query";

async function getFilings() {
  const { data } = await axios.get(`https://warrants.tech/api/all_filings`);

  return data;
}

export default function FilingsPage() {
  const { isLoading: f_loading, data: filings } = useQuery({
    queryKey: "all_filings",
    queryFn: () => getFilings(),
    config: {},
  });

  return (
    <div>
      <Box padding="1rem" d="flex" justifyContent="center">
        <Card>
          <NewsTimeline filings={!f_loading ? filings : []} />
        </Card>
      </Box>
    </div>
  );
}
