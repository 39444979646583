import React from "react"
import { Route } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import axios from "axios"
import { Redirect } from "react-router-dom"
import { useQuery } from "react-query"

async function getValidUser(email) {
  const exempt = [
    "terose73@gmail.com",
    "mike@magiscapitalpartners.com",
    "steven.b.adams@gmail.com",

    //exempt list for warrant observer
    "bevan@cashflowtactics.com",
    "kevrep1@gmail.com",
    "alexandresimard@icloud.com",
    "wmcpharlin@comcast.net",
    "annapark87@gmail.com",
    "erniemoncrieff@yahoo.com",
  ]
  if (exempt.includes(email)) {
    return true
  }

  const { data } = await axios.get(
    `https://warrants.tech/api/verify_user?email=${email}`
  )

  return data
}

const ProtectedRoute = ({ component, ...args }) => {
  const { isAuthenticated, user, isLoading, logout } = useAuth0()

  const email = user?.email
  const email_verified = user?.email_verified

  const {
    isIdle,
    isLoading: verify_loading,
    data: verified,
  } = useQuery(["verified", email], () => getValidUser(email), {
    // The query will not execute until the user is loaded
    enabled: !!email,
  })

  // } else if (!isAuthenticated) {
  //   //return <Redirect to="/login" />
  // } else if (isIdle || verify_loading) {
  //   return <div></div> //just wait, don't show anything
  // } else if (!email_verified) {
  //   // "Please verify your email (you should have received an email from Auth0 after signing up).",

  //   logout({ returnTo: window.location.origin })
  // } else if (!verified) {
  //   logout({ returnTo: window.location.origin })
  //   // return (
  //   //   <Redirect
  //   //     to={{
  //   //       pathname: "/login",
  //   //       state: {
  //   //         message:
  //   //           "Please sign up to The Warrant Observer. Note that it may take our payment processor a day or two to register new users.",
  //   //       },
  //   //     }}
  //   //   />
  //   // );
  // } else {
  return <Route {...args} component={component} />
}

export default ProtectedRoute
