import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuItem from "./MenuItem";
import { Link as RouterLink } from "react-router-dom";
import logo from "../img/logo.png";
import LogoutButton from "./LogoutButton";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  toolbarText: {
    fontWeight: 600,
  },
}));

export default function Header({ options, title }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <Box display="flex" alignItems="center">
          <Typography
            variant="h5"
            color="secondary"
            align="center"
            noWrap
            className={classes.toolbarText}
          >
            {title}
          </Typography>

          <Box p="1rem">
            <Avatar alt="Logo" src={logo} />
          </Box>
        </Box>

        {matches ? (
          <>
            {options.map((option) => {
              return (
                <Link
                  color="primary"
                  noWrap
                  variant="body2"
                  key={option.name}
                  component={RouterLink}
                  to={option.link}
                >
                  <Typography
                    variant="subtitle1"
                    color="inherit"
                    align="center"
                    noWrap
                  >
                    <b> {option.name} </b>
                  </Typography>
                </Link>
              );
            })}
            <LogoutButton />
          </>
        ) : (
          <MenuItem options={options} />
        )}
      </Toolbar>
    </>
  );
}
