import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import { format } from "d3-format"
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
  Crosshair,
  MarkSeries,
  Hint,
} from "react-vis"
import parseISO from "date-fns/parseISO"
import differenceInYears from "date-fns/differenceInYears"
//import Link from "@material-ui/core/Link";

export default function WarrantGraph({
  warrant_price_information,
  tick_label_angle,
  filings,
  approved_tickers,
}) {
  const [point, setPoint] = useState([])
  const [pointMark, setPointMark] = useState({})

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box width="90vw" height="50vh" paddingTop="1rem">
        <FlexibleXYPlot xType="time" onMouseLeave={() => setPoint([])}>
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis tickLabelAngle={tick_label_angle} />
          <Hint value={pointMark ? pointMark : []}>
            <div background="black">
              <p style={{ color: "#0063B2FF" }}>{pointMark.type}</p>
            </div>
          </Hint>

          <LineSeries
            color="purple"
            //   curve={configuredCurve}

            curve={"curveMonotoneX"}
            data={
              warrant_price_information.length
                ? warrant_price_information.map(({ adjClose, date }) => {
                    return { y: adjClose, x: parseISO(date) }
                  })
                : []
            }
            onNearestX={(v, { event, innerX, index }) => setPoint([v, index])}
          />

          <Crosshair
            values={point}
            titleFormat={(d) => ({
              title: "Date",
              value: new Date(d[0].x).toLocaleDateString(),
            })}
            itemsFormat={(d) => [
              { title: "price", value: `$ ${d[0].y.toFixed(2)}` },
              {
                title: "volume",
                value: format(".1s")(
                  warrant_price_information[d[1]].adjVolume
                    ? warrant_price_information[d[1]].adjVolume
                    : 0
                ),
              },
            ]}
          >
            {/* warrant_price_information[point[1]].adjVolume */}

            {/* Divs inside Crosshair Component required to prevent value box render */}
          </Crosshair>

          <YAxis title="Warrant Price" orientation="left" />
        </FlexibleXYPlot>
      </Box>

      <Box width="90vw" height="20vh" paddingTop="1rem">
        <FlexibleXYPlot xType="time">
          <YAxis
            title="Volume"
            orientation="left"
            tickTotal={3}
            tickFormat={(value) => format(".1s")(value)}
          />
          <VerticalGridLines />
          <HorizontalGridLines />

          <LineSeries
            color="#880e4f"
            //   curve={configuredCurve}

            curve={"curveMonotoneX"}
            data={
              warrant_price_information
                ? warrant_price_information.map(({ adjVolume, date }) => {
                    return { y: adjVolume ? adjVolume : 0, x: parseISO(date) }
                  })
                : []
            }
          />
        </FlexibleXYPlot>
      </Box>
    </Box>
  )
}
