import React, { useState } from "react";
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import TextFormField from "./FormFields/TextFormField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import axios from "axios";
import Typography from "@material-ui/core/Typography";

const schema = yup.object({
  name: yup.string().min(3),
  email: yup.string().email().required(),
  message: yup.string().required().min(10),
});

export default function ContactForm() {
  const [serverState, setServerState] = useState();
  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg });
  };
  const handleOnSubmit = (values, actions) => {
    axios({
      method: "POST",
      url: "https://formspree.io/xzbjglvb",
      data: values,
    })
      .then((response) => {
        actions.setSubmitting(false);
        actions.resetForm();
        handleServerResponse(true, "Thanks, we will get back to you soon!");
      })
      .catch((error) => {
        actions.setSubmitting(false);
        handleServerResponse(false, error.response.data.error);
      });
  };
  return (
    <Formik
      validationSchema={schema}
      initialValues={{ name: "", email: "", message: "" }}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting }) => (
        <Form style={{ width: "20rem", height: "21rem" }}>
          <div>
            <Field label="Name" name="name" component={TextFormField} />
          </div>
          <div>
            <Field label="Email" name="email" component={TextFormField} />
          </div>
          <div>
            <Field
              label="Message"
              name="message"
              component={TextFormField}
              multiline={true}
              rows={3}
              variant="outlined"
              fullWidth={true}
            />
          </div>
          <Box display="flex" justifyContent="center">
            <Button variant="outlined" type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </Box>
          {serverState && (
            <p className={!serverState.ok ? "errorMsg" : ""}>
              <Typography variant="subtitle1" color="primary">
                {serverState.msg}
              </Typography>
            </p>
          )}
        </Form>
      )}
    </Formik>
  );
}
