import React from "react";
import { Switch, Redirect } from "react-router-dom";
import HomePage from "./pages/HomePage.js";
import ContactPage from "./pages/ContactPage.js";
import DetailsPage from "./pages/DetailsPage.js";
import FilingsPage from "./pages/FilingsPage.js";
import NewsPage from "./pages/NewsPage.js";
import GraphPage from "./pages/GraphPage.js";
import WatchListPage from "./pages/WatchListPage.js";
import useGoogleAnalytics from "./useGoogleAnalytics";
import ProtectedRoute from "./ProtectedRoute.js";
import TrendsPage from "./pages/TrendsPage.js";
export default function Routes() {
  useGoogleAnalytics();

  return (
    <Switch>
      <ProtectedRoute exact path="/" component={HomePage} />

      <ProtectedRoute path="/contact" component={ContactPage} />

      <ProtectedRoute path="/filings" component={FilingsPage} />

      <ProtectedRoute path="/news" component={NewsPage} />

      <ProtectedRoute path="/watchlist" component={WatchListPage} />

      <ProtectedRoute path="/details/:id" children={<DetailsPage />} />

      <ProtectedRoute path="/trends" children={<GraphPage />} />

      <ProtectedRoute path="/bubbles" children={<TrendsPage />} />

      <ProtectedRoute path="/">
        {/* route to the home page if url is not found */}
        <Redirect to="/" />
      </ProtectedRoute>
    </Switch>
  );
}
