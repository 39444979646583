import React from "react";

import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Avatar from "@material-ui/core/Avatar";
import TrendGraph from "../components/TrendGraph";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import hot from "../img/hot.png";
import cold from "../img/cold.png";
import medium from "../img/medium.webp";
import { useQuery } from "react-query";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    justifyContent: "space-evenly",
    margin: "1rem 1rem 1rem 1rem",
    padding: "1rem 0rem 1rem 0rem",
  },

  title2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "2rem 1rem 1rem 1rem",
    padding: "1rem 0rem 1rem 0rem",
  },
  title3: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    margin: "1rem 1rem 1rem 1rem",
    padding: "1rem 0rem 1rem 0rem",
  },
  heading: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

async function getGraph() {
  const { data } = await axios.get(`https://warrants.tech/api/wt_graph`);

  return data;
}

export default function GraphPage() {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();

  const { isLoading: a_loading, data: avg_graph } = useQuery({
    queryKey: "average_graph",
    queryFn: () => getGraph(),
    config: {},
  });

  return (
    <div>
      <div>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding="1rem"
        >
          <Box fontWeight="fontWeightBold">
            <Typography color="secondary" variant="h4" align="center">
              <b> Average SPAC Warrant Prices Over Time </b>
            </Typography>
          </Box>
        </Box>
        <Card className={classes.title}>
          <TrendGraph price_history={!a_loading ? avg_graph : []} />
        </Card>

        <Box
          marginLeft={matches ? "6rem" : "1rem"}
          marginRight={matches ? "6rem" : "1rem"}
          marginTop="2rem"
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div style={{ width: "100%" }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  paddingBottom="1.5rem"
                  paddingTop="1.5rem"
                  component="span"
                >
                  <Box>
                    <Typography
                      color="primary"
                      variant="h4"
                      className={classes.heading}
                    >
                      {">$2"} | SPACs are HOT
                    </Typography>
                  </Box>
                  <Box marginLeft="2rem">
                    <Avatar alt="hot" src={hot} />
                  </Box>
                </Box>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                When SPAC warrants are on average above $2, this is a prime
                indicator for a bubble forming in the space. Naked warrants may
                be risky and arbitrage strategies with options or other downside
                protection may be optimal. At a 2% interest rate, implied
                volatility is about 24.44% at $2 for a SPAC warrant. Including
                the banker fees, sponsor promote, and warrant dilution (which
                are about a median of 15%) the implied volaility moves up to
                34.79%.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <div style={{ width: "100%" }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  component="span"
                  paddingBottom="1.5rem"
                  paddingTop="1.5rem"
                >
                  <Box>
                    <Typography
                      color="primary"
                      variant="h4"
                      className={classes.heading}
                    >
                      {"$1-$2"} | SPACs are MEDIUM
                    </Typography>
                  </Box>
                  <Box marginLeft="2rem">
                    <Avatar alt="medium" src={medium} />
                  </Box>
                </Box>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                When SPAC warrants are on average between $1 and $2, this is
                typically indicative of normal environment with no surprises.
                All strategies are open for business, and warrants may be a
                great alternative to longer term call options. At a 2% interest
                rate, implied volatility is about 18.76% at $1.50 for a SPAC
                warrant. Including the banker fees, sponsor promote, and warrant
                dilution (which are about a median of 15%) the implied volaility
                moves up to 28.19%.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <div style={{ width: "100%" }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  paddingBottom="1.5rem"
                  paddingTop="1.5rem"
                  component="span"
                >
                  <Box>
                    <Typography
                      color="primary"
                      variant="h4"
                      className={classes.heading}
                    >
                      {"<$1"} | SPACs are COLD
                    </Typography>
                  </Box>
                  <Box marginLeft="2rem">
                    <Avatar alt="cool" src={cold} />
                  </Box>
                </Box>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                When SPAC warrants are on average below $1, there might be a
                recession in the overall market or a liquidity shock. Bullish
                investors may be able to get cheap leverage through warrants
                rather than expressing their views on the common stock. At a 2%
                interest rate, implied volatility is about 13.14% at $1.00 for a
                SPAC warrant. Including the banker fees, sponsor promote, and
                warrant dilution (which are about a median of 15%) the implied
                volaility moves up to 21.56%.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Box marginTop="2rem">
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName="WarrantsTech"
              options={{ height: 900 }}
            />
          </Box>
        </Box>
      </div>
    </div>
  );
}
