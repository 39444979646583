import React from "react"
import { useParams } from "react-router-dom"
import WarrantGraph from "../components/WarrantGraph.js"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Typography from "@material-ui/core/Typography"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import axios from "axios"
import Link from "@material-ui/core/Link"
import TimelineComponent from "../components/TimelineComponent.js"
import WatchListButton from "../components/WatchListButton.js"
import NewsCard from "../components/NewsCard.js"
import Grid from "@material-ui/core/Grid"
import parse from "date-fns/parse"
import format from "date-fns/format"
import { format as d3_format } from "d3-format"
import TweetCard from "../components/TweetCard"
import { useQuery } from "react-query"
import { Helmet } from "react-helmet"

const useStyles = makeStyles({
  title: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignItems: "center",
    margin: "1rem 1rem 1rem 1rem",
    padding: "1rem 0rem 1rem 0rem",
  },

  desc: {
    display: "flex",
    justifyContent: "space-evenly",
    margin: "1rem 3rem 1rem 3rem",
    padding: "1rem 0rem 1rem 0rem",
  },

  desc2: {
    display: "flex",
    justifyContent: "space-evenly",
    margin: "1rem 1rem 1rem 1rem",
    padding: "1rem 0rem 1rem 0rem",
  },

  shadow: {
    textShadow: "2px 2px 0px rgba(152, 118, 54, 0.1)",
    fontWeight: 550,
  },
  shadow2: {
    textShadow: "2px 2px 0px rgba(100, 118, 0, 0.1)",
    fontWeight: 250,
  },
  title2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "2rem 1rem 1rem 1rem",
    padding: "1rem 0rem 1rem 0rem",
  },
  title3: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    margin: "1rem 1rem 1rem 1rem",
    padding: "1rem 1rem 1rem 1rem",
  },

  title4: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    margin: "1rem 1rem 1rem 1rem",
    padding: "1rem 0rem 1rem 0rem",
  },
  emoji: {
    fontSize: "2rem",

    marginLeft: "1rem",
  },
  emoji2: {
    fontSize: "1.5rem",

    marginLeft: "1rem",
  },
})

async function getPriceHistory(id) {
  const { data } = await axios.get(
    `https://warrants.tech/api/price_history?common_ticker=${id}`
  )
  return data
}

async function getFilings(id) {
  const { data } = await axios.get(
    `https://warrants.tech/api/filings?common_ticker=${id}`
  )

  return data
}

async function getNews(id) {
  const { data } = await axios.get(
    `https://warrants.tech/api/news?common_ticker=${id}`
  )

  return data
}

async function getTweets(id) {
  const { data } = await axios.get(
    `https://warrants.tech/api/tweets?common_ticker=${id}`
  )

  return data
}

async function getPrice(id) {
  const { data } = await axios.get(
    `https://warrants.tech/api/quote?common_ticker=${id}`
  )
  return data
}

async function getOptions(id) {
  const { data } = await axios.get(
    `https://warrants.tech/api/options?common_ticker=${id}`
  )

  return data
}

async function getInsiders(id) {
  const { data } = await axios.get(
    `https://warrants.tech/api/insiders?common_ticker=${id}`
  )

  return data
}

export default function DetailsPage() {
  let { id } = useParams()
  const theme = useTheme()

  const { isLoading: wt_loading, data: warrant } = useQuery({
    queryKey: "price_history",
    queryFn: () => getPriceHistory(id),
    config: {},
  })

  const { isLoading: f_loading, data: filings } = useQuery({
    queryKey: "filings",
    queryFn: () => getFilings(id),
    config: {},
  })

  const { isLoading: n_loading, data: news } = useQuery({
    queryKey: "news",
    queryFn: () => getNews(id),
    config: {},
  })

  const { isLoading: t_loading, data: tweets } = useQuery({
    queryKey: "tweets",
    queryFn: () => getTweets(id),
    config: {},
  })

  const { isLoading: p_loading, data: price } = useQuery({
    queryKey: "price",
    queryFn: () => getPrice(id),
    config: {},
  })

  const { isLoading: o_loading, data: options } = useQuery({
    queryKey: "options",
    queryFn: () => getOptions(id),
    config: {},
  })

  const { isLoading: i_loading, data: insiders } = useQuery({
    queryKey: "insiders",
    queryFn: () => getInsiders(id),
    config: {},
  })

  const matches = useMediaQuery(theme.breakpoints.up("sm"))
  const classes = useStyles()

  const approved_tickers = [
    "S-1",
    "S-1/A",
    "424B3",
    "424B4",
    "S-1MEF",
    "8-K",
    "425",
    "DEF 14A",
    "PRE 14A",
    "PRER14A",
  ]

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ]

  return (
    <div>
      {!wt_loading & !p_loading && price.warrant_pricing_information ? (
        <Helmet>
          <title>
            {`${warrant.warrant_ticker} | ${
              price.warrant_pricing_information.latestPrice
            } ${d3_format(".0%")(
              Math.abs(price.warrant_pricing_information.changePercent)
            )} ${
              price.warrant_pricing_information.changePercent > 0
                ? "📈"
                : price.warrant_pricing_information.changePercent < 0
                ? "📉"
                : ""
            } | ${months[new Date().getMonth()]} ${new Date().getDate()}`}{" "}
          </title>
          <meta
            name="description"
            content={`${warrant.warrant_ticker} (${id}) ${warrant.companyName} Warrant Information - Graphs, Filings, Insiders, etc! All-in-one warrant platform, trading and research insights for warrant enthusiasts!`}
          />
        </Helmet>
      ) : (
        ""
      )}

      {matches ? (
        <div>
          <Card className={classes.title}>
            <Typography variant="h4" color="secondary">
              {!wt_loading ? warrant.companyName : ""}
            </Typography>
            <Typography variant="h4" color="secondary">
              {!wt_loading ? warrant.warrant_ticker : ""}
            </Typography>

            <Typography variant="h4" color="secondary">
              {!p_loading && price.warrant_pricing_information
                ? price.warrant_pricing_information.latestPrice
                : ""}
            </Typography>

            {!p_loading && price.warrant_pricing_information ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography variant="h4" color="secondary">
                  {d3_format(".0%")(
                    Math.abs(price.warrant_pricing_information.changePercent)
                  )}
                </Typography>

                {price.warrant_pricing_information.changePercent > 0 ? (
                  <span
                    role="img"
                    aria-label="arrow-down"
                    className={classes.emoji}
                  >
                    📈
                  </span>
                ) : price.warrant_pricing_information.changePercent < 0 ? (
                  <span
                    role="img"
                    aria-label="arrow-down"
                    className={classes.emoji}
                  >
                    📉
                  </span>
                ) : (
                  ""
                )}
              </Box>
            ) : (
              ""
            )}

            <WatchListButton ticker={id} />
          </Card>

          <Box padding="0.2rem">
            {(!wt_loading && warrant && warrant.regex_description) ||
            (!p_loading &&
              !wt_loading &&
              warrant.spac &&
              price.common_pricing_information &&
              price.common_pricing_information.latestPrice - 11.5 >
                price.warrant_pricing_information.latestPrice) ? (
              <Card className={classes.desc}>
                <Box paddingLeft="3rem" paddingRight="3rem">
                  {!wt_loading && warrant && warrant.regex_description ? (
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      align="center"
                    >
                      {warrant.regex_description}
                    </Typography>
                  ) : (
                    ""
                  )}

                  {!wt_loading &&
                  warrant &&
                  warrant.target &&
                  warrant.target.length > 1 ? (
                    <Box
                      marginTop="1rem"
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography
                        variant="button"
                        color="inherit"
                        align="center"
                      >
                        {warrant.link_investorpres &&
                        warrant.link_investorpres.length > 1 ? (
                          <div>
                            <Link
                              href={warrant.link_investorpres}
                              target="_blank"
                              rel="noreferrer"
                            >
                              TARGET IDENTIFIED: {warrant.target}
                            </Link>
                          </div>
                        ) : (
                          <div> TARGET IDENTIFIED: {warrant.target} </div>
                        )}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}

                  {!wt_loading &&
                  warrant &&
                  warrant.target_desc &&
                  warrant.target_desc.length > 1 ? (
                    <Box
                      marginTop="1rem"
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        align="center"
                      >
                        {warrant.target_desc}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}

                  {!p_loading &&
                  !wt_loading &&
                  warrant.spac &&
                  price.common_pricing_information &&
                  price.common_pricing_information.latestPrice - 11.5 >
                    price.warrant_pricing_information.latestPrice ? (
                    <Box
                      marginTop="1rem"
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography
                        variant="button"
                        color="inherit"
                        align="center"
                      >
                        {`Intrinsic Value: $${(
                          price.common_pricing_information.latestPrice - 11.5
                        ).toFixed(2)} \u00A0 \u00A0 \u00A0
                       Discount to IV: ${(
                         (1 -
                           price.warrant_pricing_information.latestPrice /
                             (price.common_pricing_information.latestPrice -
                               11.5)) *
                         100
                       ).toFixed(2)}%`}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </Card>
            ) : (
              ""
            )}

            {!o_loading && options.length ? (
              <Box padding="0.2rem" marginLeft="2rem" marginRight="2rem">
                <Card className={classes.title}>
                  <Box marginLeft="2rem" marginRight="2rem">
                    <Typography
                      variant="button"
                      color="secondary"
                      className={classes.shadow}
                    >
                      Option Expiry Dates
                    </Typography>
                  </Box>
                  {options.map((date) => (
                    <Box marginLeft="1rem" marginRight="1rem">
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        key={date}
                      >
                        {date.length === 6
                          ? format(
                              parse(date, "yyyyMM", new Date()),
                              "MMM yyyy"
                            )
                          : format(
                              parse(date, "yyyyMMdd", new Date()),
                              "MMM dd, yyyy"
                            )}
                      </Typography>
                    </Box>
                  ))}
                </Card>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </div>
      ) : (
        <div>
          <Card className={classes.title2}>
            <Box>
              <Typography
                variant="h6"
                color="secondary"
                className={classes.shadow}
              >
                {!wt_loading ? warrant.companyName : ""}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-evenly"
              width="100%"
              flexWrap="wrap"
              marginTop="1rem"
            >
              <Typography
                variant="h6"
                color="secondary"
                fontStyle="italic"
                m={1}
              >
                <Box fontStyle="italic" fontWeight="fontWeightBold">
                  {!wt_loading ? warrant.warrant_ticker : ""}
                </Box>
              </Typography>
              <Typography
                variant="h6"
                color="secondary"
                fontStyle="italic"
                m={1}
              >
                <Box fontStyle="italic" fontWeight="fontWeightBold">
                  {!p_loading && price.warrant_pricing_information
                    ? price.warrant_pricing_information.latestPrice
                    : ""}
                </Box>
              </Typography>

              {!p_loading ? (
                <Box display="flex" alignItems="start">
                  <Typography
                    variant="h6"
                    color="secondary"
                    fontStyle="italic"
                    m={1}
                    fontWeight="fontWeightBold"
                  >
                    <Box fontWeight="fontWeightBold">
                      {d3_format(".0%")(
                        Math.abs(
                          price.warrant_pricing_information.changePercent
                        )
                      )}
                    </Box>
                  </Typography>

                  {price.warrant_pricing_information.changePercent > 0 ? (
                    <span
                      role="img"
                      aria-label="arrow-down"
                      className={classes.emoji2}
                    >
                      📈
                    </span>
                  ) : price.warrant_pricing_information.changePercent < 0 ? (
                    <span
                      role="img"
                      aria-label="arrow-down"
                      className={classes.emoji}
                    >
                      📉
                    </span>
                  ) : (
                    ""
                  )}
                </Box>
              ) : (
                ""
              )}

              <Box
                fontStyle="italic"
                fontWeight="fontWeightBold"
                marginLeft="5rem"
                marginRight="5rem"
                marginTop="0.5rem"
              >
                <WatchListButton ticker={id} />
              </Box>
            </Box>
          </Card>

          <Box padding="0.2rem">
            {(!wt_loading && warrant && warrant.regex_description) ||
            (!p_loading &&
              !wt_loading &&
              warrant.spac &&
              price.common_pricing_information &&
              price.common_pricing_information.latestPrice - 11.5 >
                price.warrant_pricing_information.latestPrice) ? (
              <Card className={classes.desc2}>
                <Box paddingLeft="1rem" paddingRight="1rem">
                  {!wt_loading && warrant && warrant.regex_description ? (
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      align="center"
                    >
                      {warrant.regex_description}
                    </Typography>
                  ) : (
                    ""
                  )}
                  {!wt_loading &&
                  warrant &&
                  warrant.target &&
                  warrant.target.length > 1 ? (
                    <Box
                      marginTop="1rem"
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography
                        variant="button"
                        color="inherit"
                        align="center"
                      >
                        {warrant.link_investorpres &&
                        warrant.link_investorpres.length > 1 ? (
                          <div>
                            <Link
                              href={warrant.link_investorpres}
                              target="_blank"
                              rel="noreferrer"
                            >
                              TARGET IDENTIFIED: {warrant.target}
                            </Link>
                          </div>
                        ) : (
                          <div> TARGET IDENTIFIED: {warrant.target} </div>
                        )}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}

                  {!wt_loading &&
                  warrant &&
                  warrant.target_desc &&
                  warrant.target_desc.length > 1 ? (
                    <Box
                      marginTop="1rem"
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        align="center"
                      >
                        {warrant.target_desc}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}
                  {!p_loading &&
                  !wt_loading &&
                  warrant.spac &&
                  price.common_pricing_information &&
                  price.common_pricing_information.latestPrice - 11.5 >
                    price.warrant_pricing_information.latestPrice ? (
                    <Box marginTop="1rem" display="flex" marginLeft="2rem">
                      <Typography variant="button" align="center">
                        {`Intrinsic Value: $${(
                          price.common_pricing_information.latestPrice - 11.5
                        ).toFixed(2)}
                      \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0
                      \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 Discount
                      to IV: ${(
                        (1 -
                          price.warrant_pricing_information.latestPrice /
                            (price.common_pricing_information.latestPrice -
                              11.5)) *
                        100
                      ).toFixed(2)}
                      %`}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </Card>
            ) : (
              ""
            )}

            {!i_loading && insiders.length && !wt_loading ? (
              <Box padding="0.2rem">
                <Card className={classes.title4}>
                  <Typography
                    variant="button"
                    color="secondary"
                    className={classes.shadow}
                  >
                    Insiders
                  </Typography>
                  {insiders.map((insider) => (
                    <Typography variant="subtitle2" key={insider}>
                      <Link
                        href={`https://www.google.com/search?&q=${
                          insider.split(" ")[0]
                        }+${insider.split(" ")[1]}+${warrant.companyName}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {insider}
                      </Link>
                    </Typography>
                  ))}
                </Card>
              </Box>
            ) : (
              ""
            )}

            {!o_loading && options.length ? (
              <Box padding="0.2rem">
                <Card className={classes.title3}>
                  <Box marginLeft="2rem" marginRight="2rem" marginBottom="1rem">
                    <Typography
                      variant="button"
                      color="secondary"
                      className={classes.shadow}
                    >
                      Option Expiry Dates
                    </Typography>
                  </Box>
                  {options.map((date) => (
                    <Box marginLeft="1rem" marginRight="1rem">
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        key={date}
                      >
                        {date.length === 6
                          ? format(
                              parse(date, "yyyyMM", new Date()),
                              "MMM yyyy"
                            )
                          : format(
                              parse(date, "yyyyMMdd", new Date()),
                              "MMM dd, yyyy"
                            )}
                      </Typography>
                    </Box>
                  ))}
                </Card>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </div>
      )}

      {matches ? (
        <WarrantGraph
          tick_label_angle={-20}
          warrant_price_information={
            !wt_loading && warrant.warrant_historical_prices
              ? warrant.warrant_historical_prices
              : []
          }
          filings={!f_loading ? filings : []}
          approved_tickers={approved_tickers}
        />
      ) : (
        <WarrantGraph
          tick_label_angle={-50}
          warrant_price_information={
            !wt_loading && warrant.warrant_historical_prices
              ? warrant.warrant_historical_prices
              : []
          }
          filings={!f_loading ? filings : []}
          approved_tickers={approved_tickers}
        />
      )}

      {!t_loading && tweets && tweets.messages && tweets.messages.length ? (
        <Grid container spacing={2} justify="center">
          {tweets.messages.map((message) => (
            <Grid item key={message.id} lg={10} md={11} xs={12}>
              <TweetCard tweet={message} />
            </Grid>
          ))}
        </Grid>
      ) : (
        ""
      )}

      {!wt_loading && !f_loading && filings.length ? (
        <Card className={classes.title2}>
          <Box display="flex" flexDirection="column">
            <TimelineComponent
              filings={filings}
              approved_tickers={approved_tickers}
            />

            <Box display="flex" justifyContent="center">
              <Link
                href={`https://www.sec.gov/cgi-bin/browse-edgar?CIK=${warrant.common_ticker}&owner=exclude&action=getcompany`}
                target="_blank"
                rel="noreferrer"
              >
                <Button color="secondary" variant="outlined">
                  SEC EDGAR Filings
                </Button>
              </Link>
            </Box>
          </Box>
        </Card>
      ) : (
        ""
      )}
    </div>
  )
}
