import React from "react";

import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
} from "react-vis";
import parseISO from "date-fns/parseISO";
import Box from "@material-ui/core/Box";

export default function TrendGraph({ price_history }) {
  return (
    <Box width="85vw" height="50vh" paddingTop="1rem">
      <FlexibleXYPlot xType="time" margin={{ bottom: 35 }}>
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis style={{ marginBottom: "50px" }} />

        <LineSeries
          color="navy"
          //   curve={configuredCurve}
          curve={"curveMonotoneX"}
          data={
            price_history
              ? price_history.map(({ avg_wt, timestamp }) => {
                  return { x: parseISO(timestamp), y: avg_wt };
                })
              : []
          }
        />
        <YAxis
          title="Avg Wt Price"
          orientation="left"
          tickLabelAngle={5}
          style={{
            line: { stroke: "#ADDDE1" },
            ticks: { stroke: "#ADDDE1", fontSize: ".9rem" },
            text: { stroke: "none", fill: "#6b6b76", fontWeight: 900 },
          }}
        />
      </FlexibleXYPlot>
    </Box>
  );
}
