import React from "react"
import Routes from "./Routes.js"
import Header from "./components/Header.js"
import Footer from "./components/Footer.js"

export default function MainComponent() {
  return (
    <>
      <Header
        title="WARRANT TRACKER"
        options={[
          { link: "/", name: "Table" },
          { link: "/watchlist", name: "Watchlist" },
          { link: "/bubbles", name: "Bubbles" },
          { link: "/contact", name: "Contact" },
        ]}
      />

      <div
        style={{
          background: "rgba(243,243,243, 0.1)",
        }}
      >
        <Routes />
        <Footer />
      </div>
    </>
  )
}
