import React, { Component } from "react";
import axios from "axios";

const WarrantContext = React.createContext();

export default class WarrantProvider extends Component {
  state = {
    warrants: {},
    robintrack: {},
  };

  fetchData = async () => {
    const { data } = await axios.get("https://warrants.tech/api/list");

    const warrants_clone = await this.fetchPrices(data);
    this.setState({ warrants: warrants_clone });
  };

  fetchPrices = async (warrants) => {
    const warrants_clone = {};

    const { data } = await axios.get(`https://warrants.tech/api/quotes`);

    for await (let [key, value] of Object.entries(warrants)) {
      let cloned_data = {};
      if (
        data[key] &&
        data[key].warrant_pricing_information &&
        data[key].warrant_pricing_information.latestPrice
      ) {
        data[key].warrant_pricing_information.latestPrice = Number(
          data[key].warrant_pricing_information.latestPrice
        ).toFixed(2);

        cloned_data = { ...value, ...data[key] };
      } else {
        cloned_data = { ...value };
      }

      warrants_clone[key] = cloned_data;
    }
    return warrants_clone;
  };

  render() {
    return (
      <WarrantContext.Provider
        value={{
          ...this.state,
          fetchData: this.fetchData,
          fetchPrices: this.fetchPrices,
        }}
      >
        {this.props.children}
      </WarrantContext.Provider>
    );
  }
}

const WarrantConsumer = WarrantContext.Consumer;

export { WarrantConsumer, WarrantContext };
