import React from "react";

import { BrowserRouter as Router, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage.js";
import { Switch } from "react-router-dom";

import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";

import { Helmet } from "react-helmet";
import MainComponent from "./MainComponent.js";

function App() {
  let theme = createMuiTheme({
    typography: {
      fontFamily: ["Montserrat", "Roboto"].join(","),
    },
    palette: {
      primary: {
        main: "#3C1874",
      },
      secondary: {
        main: "#932432",
      },
    },
  });
  theme = responsiveFontSizes(theme);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <div className="App">
      <Helmet>
        <title>
          {`${months[new Date().getMonth()]} ${new Date().getDate()}`} - Warrant
          Tracker Platform (Including SPACs) 🚀
        </title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route
              exact
              path="/login"
              render={(props) => <LoginPage {...props} />}
            />
            <Route path="/" component={MainComponent} />
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
