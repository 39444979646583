import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Timeline from "@material-ui/lab/Timeline"
import TimelineItem from "@material-ui/lab/TimelineItem"
import TimelineSeparator from "@material-ui/lab/TimelineSeparator"
import TimelineConnector from "@material-ui/lab/TimelineConnector"
import TimelineContent from "@material-ui/lab/TimelineContent"
import TimelineDot from "@material-ui/lab/TimelineDot"
import parseISO from "date-fns/parseISO"
import format from "date-fns/format"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Link from "@material-ui/core/Link"
import bytes from "bytes"
import differenceInYears from "date-fns/differenceInYears"

const useStyles = makeStyles({
  description: {
    minWidth: "65vw",
  },
  date: {
    minWidth: "15vw",
  },
  paper: {
    padding: "6px 16px",
  },
})

export default function TimelineComponent({ filings, approved_tickers }) {
  const classes = useStyles()

  const bad = new Set([
    "SC13G",
    "SC13G/A",
    "4",
    "3",
    "3/A",
    "4/A",
    "SC13D",
    "SC13D/A",
  ])

  return <></>
}
