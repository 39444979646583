import React, { Component } from "react";

const SliderContext = React.createContext();

export default class SliderProvider extends Component {
  state = {
    value: [0, 20],
  };

  componentDidMount() {
    const low = window.localStorage.getItem("low");
    const high = window.localStorage.getItem("high");

    if (low && high) {
      this.setState({ value: [Number(low), Number(high)] });
    }
  }
  setValue = (value) => {
    this.setState({ value });
  };

  render() {
    return (
      <SliderContext.Provider
        value={{
          ...this.state,
          setValue: this.setValue,
        }}
      >
        {this.props.children}
      </SliderContext.Provider>
    );
  }
}

const SliderConsumer = SliderContext.Consumer;

export { SliderConsumer, SliderContext };
