import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import bytes from "bytes";

const useStyles = makeStyles({
  description: {
    minWidth: "60vw",
  },
  date: {
    minWidth: "20vw",
  },
  paper: {
    padding: "6px 16px",
  },
});

export default function TimelineComponent({ filings }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const bad = new Set([
    "SC13G",
    "SC13G/A",
    "SC13D",
    "SC13D/A",
    "EFFECT",
    "CERT",
    "4",
    "4/A",
    "3",
    "3/A",
  ]);

  return (
    <div>
      {filings.length ? (
        <Timeline>
          {filings
            .filter(
              (filing) => !bad.has(filing.filing.formType.replace(/\s/g, ""))
            )
            .map((filing, i) => {
              return (
                <TimelineItem key={filing.filedAt}>
                  <TimelineContent className={classes.date} align="right">
                    <Typography color="secondary">
                      <b>
                        {format(
                          parseISO(filing.filing.filedAt),
                          "MMM dd yyyy h:mm a"
                        )}{" "}
                      </b>
                    </Typography>
                  </TimelineContent>
                  <TimelineSeparator>
                    <TimelineDot variant="outlined" color="primary">
                      <Typography color="secondary" variant="subtitle2">
                        {filing.common_ticker}
                      </Typography>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>

                  <TimelineContent className={classes.description}>
                    <Box display="flex" flexGrow="0">
                      <Typography color="secondary">
                        <Paper elevation={3} className={classes.paper}>
                          <Link
                            href={filing.filing.linkToFilingDetails}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {matches
                              ? filing.filing.description
                              : filing.filing.formType}{" "}
                            |{" "}
                            {bytes(parseInt(filing.filing.size), {
                              decimalPlaces: 0,
                              unitSeparator: " ",
                            })}
                          </Link>
                        </Paper>
                      </Typography>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
        </Timeline>
      ) : (
        ""
      )}
    </div>
  );
}
