import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import jam6 from "../img/jam6.jpg";
import jam5 from "../img/jam5.jpg";
import jam4 from "../img/jam4.jpg";
import jam3 from "../img/jam3.jpg";
import jam2 from "../img/jam2.jpg";
import jam1 from "../img/jam1.jpg";

const useStyles = makeStyles({
  img: {
    maxWidth: "60%",
    height: "auto",
  },
});

export default function JarAvatar({ slider_value }) {
  const classes = useStyles();
  return (
    <div>
      {(() => {
        if (slider_value[1] >= 20.0)
          return (
            <Avatar
              alt="jam6"
              variant="square"
              className={classes.img}
              src={jam6}
            />
          );
        else if (slider_value[1] >= 16.0)
          return (
            <Avatar
              alt="jam5"
              variant="square"
              className={classes.img}
              src={jam5}
            />
          );
        else if (slider_value[1] >= 12.0)
          return (
            <Avatar
              alt="jam4"
              variant="square"
              className={classes.img}
              src={jam4}
            />
          );
        else if (slider_value[1] >= 8.0)
          return (
            <Avatar
              alt="jam3"
              variant="square"
              className={classes.img}
              src={jam3}
            />
          );
        else if (slider_value[1] >= 4.0)
          return (
            <Avatar
              alt="jam2"
              variant="square"
              className={classes.img}
              src={jam2}
            />
          );
        else
          return (
            <Avatar
              alt="jam1"
              variant="square"
              className={classes.img}
              src={jam1}
            />
          );
      })()}
    </div>
  );
}
